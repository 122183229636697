.active-sidebar {
    display: block;
    width: 350px;
    z-index: 1001;
    background: #ffffff;
      background-color: rgb(255, 255, 255);
    bottom: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.vertical-menu {
  padding-top: 10px !important;
}