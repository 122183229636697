.img-card {
    border-radius: 20px !important;
    /* height: 12rem; */
    overflow: hidden;
}

.btn-rounded {
    border-radius: 10px !important;
}

.pointer {
    cursor: pointer;
}