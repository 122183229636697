body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.well {
  background-color: #f9f9f9 !important;
}

.btn-primary, .text-primary {
  color: orange;
}

.alert-danger {
  background-color: #f44336 !important;
  color: white;
  font-weight: 600;
}

.text-right {
  text-align: right !important;
}

.package-image{
  height: 20vh !important;
  width: 100%;
}